export const Star = ({fill}: { fill: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.7733 15.1135C11.42 15.1135 10.9667 15.0001 10.4 14.6668L8.40666 13.4868C8.2 13.3668 7.8 13.3668 7.6 13.4868L5.6 14.6668C4.42 15.3668 3.72666 15.0868 3.41333 14.8601C3.10666 14.6335 2.62666 14.0535 2.94 12.7201L3.41333 10.6735C3.46666 10.4601 3.36 10.0935 3.2 9.93346L1.54666 8.28013C0.719997 7.45346 0.786664 6.74679 0.899997 6.40013C1.01333 6.05346 1.37333 5.44013 2.52 5.24679L4.64666 4.89346C4.84666 4.86013 5.13333 4.64679 5.22 4.46679L6.4 2.11346C6.93333 1.04013 7.63333 0.880127 8 0.880127C8.36666 0.880127 9.06666 1.04013 9.6 2.11346L10.7733 4.46013C10.8667 4.64013 11.1533 4.85346 11.3533 4.88679L13.48 5.24013C14.6333 5.43346 14.9933 6.04679 15.1 6.39346C15.2067 6.74013 15.2733 7.44679 14.4533 8.27346L12.8 9.93346C12.64 10.0935 12.54 10.4535 12.5867 10.6735L13.06 12.7201C13.3667 14.0535 12.8933 14.6335 12.5867 14.8601C12.42 14.9801 12.1533 15.1135 11.7733 15.1135ZM8 12.3935C8.32666 12.3935 8.65333 12.4735 8.91333 12.6268L10.9067 13.8068C11.4867 14.1535 11.8533 14.1535 11.9933 14.0535C12.1333 13.9535 12.2333 13.6001 12.0867 12.9468L11.6133 10.9001C11.4867 10.3468 11.6933 9.63346 12.0933 9.22679L13.7467 7.57346C14.0733 7.24679 14.22 6.92679 14.1533 6.70679C14.08 6.48679 13.7733 6.30679 13.32 6.23346L11.1933 5.88013C10.68 5.79346 10.12 5.38013 9.88666 4.91346L8.71333 2.56679C8.5 2.14013 8.23333 1.88679 8 1.88679C7.76666 1.88679 7.5 2.14013 7.29333 2.56679L6.11333 4.91346C5.88 5.38013 5.32 5.79346 4.80666 5.88013L2.68666 6.23346C2.23333 6.30679 1.92666 6.48679 1.85333 6.70679C1.78 6.92679 1.93333 7.25346 2.26 7.57346L3.91333 9.22679C4.31333 9.62679 4.52 10.3468 4.39333 10.9001L3.92 12.9468C3.76666 13.6068 3.87333 13.9535 4.01333 14.0535C4.15333 14.1535 4.51333 14.1468 5.1 13.8068L7.09333 12.6268C7.34666 12.4735 7.67333 12.3935 8 12.3935Z"
        fill={fill}/>
      <path
        d="M8 12.3935C8.32666 12.3935 8.65333 12.4735 8.91333 12.6268L10.9067 13.8068C11.4867 14.1535 11.8533 14.1535 11.9933 14.0535C12.1333 13.9535 12.2333 13.6001 12.0867 12.9468L11.6133 10.9001C11.4867 10.3468 11.6933 9.63346 12.0933 9.22679L13.7467 7.57346C14.0733 7.24679 14.22 6.92679 14.1533 6.70679C14.08 6.48679 13.7733 6.30679 13.32 6.23346L11.1933 5.88013C10.68 5.79346 10.12 5.38013 9.88666 4.91346L8.71333 2.56679C8.5 2.14013 8.23333 1.88679 8 1.88679C7.76666 1.88679 7.5 2.14013 7.29333 2.56679L6.11333 4.91346C5.88 5.38013 5.32 5.79346 4.80666 5.88013L2.68666 6.23346C2.23333 6.30679 1.92666 6.48679 1.85333 6.70679C1.78 6.92679 1.93333 7.25346 2.26 7.57346L3.91333 9.22679C4.31333 9.62679 4.52 10.3468 4.39333 10.9001L3.92 12.9468C3.76666 13.6068 3.87333 13.9535 4.01333 14.0535C4.15333 14.1535 4.51333 14.1468 5.1 13.8068L7.09333 12.6268C7.34666 12.4735 7.67333 12.3935 8 12.3935Z"
        fill={fill}/>
    </svg>
  )
}
