import { EventBus } from '../EventBus';
import { Scene } from 'phaser';
import {
  gameOverStore,
  gameStore,
  GameStore,
  setGameOver,
  setTestStore,
  testQuestionsStore,
} from '@/game/game-store.tsx';
import { StoreWritable } from 'effector';

export class Game extends Scene {
  background!: Phaser.Physics.Arcade.Sprite;
  obstacles!: Phaser.Physics.Arcade.Group;
  questions!: Phaser.Physics.Arcade.Group;
  player!: Phaser.Physics.Arcade.Sprite;
  cop!: Phaser.Physics.Arcade.Sprite;
  cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  platform!: Phaser.Physics.Arcade.Group;
  isStop: boolean = false;
  Y_ZERO_OBSTACLES: number = 330;
  gameData: StoreWritable<GameStore | null>;
  attempt: number = 2;
  gameOverScreen: StoreWritable<Boolean | null>;
  isOpenModal: StoreWritable<Boolean | null>;
  colliderPlayerAndQuestions!: Phaser.Physics.Arcade.Collider;

  backgroundSound!: Phaser.Sound.BaseSound;
  jumpSound!: Phaser.Sound.BaseSound;
  questionSound!: Phaser.Sound.BaseSound;
  punchSound!: Phaser.Sound.BaseSound;
  gameOverEvent?: Phaser.Time.TimerEvent;
  badAnswerTimeEvent?: Phaser.Time.TimerEvent;
  previousPlayerX: number = 0;
  previousPlayerY: number = 0;
  isPunchOnBadAnswer: boolean = false;

  constructor() {
    super('Game');
    this.gameData = gameStore;
    this.isOpenModal = testQuestionsStore;
    this.gameOverScreen = gameOverStore;
  }

  isAndroid(): boolean {
    return navigator.userAgent.match(/Android/i) !== null;
  }

  preload() {
    this.load.image(
      'office_bg',
      this.isAndroid()
        ? '/game-assets/location-mini-mini.jpg'
        : '/game-assets/location_big_1.png',
    );
    this.load.image('bookshelf', '/game-assets/obstacles/bookshelf.png');
    this.load.image('plague', '/game-assets/obstacles/plague.png');
    this.load.image('printer', '/game-assets/obstacles/printer.png');
    this.load.image('table', '/game-assets/obstacles/table.png');
    this.load.image('platform', '/game-assets/platform.png');
    this.load.image('q-green', '/game-assets/q-green.png');
    this.load.image('q-violet', '/game-assets/q-violet.png');
    this.load.image('q-green', '/game-assets/q-green.png');

    this.load.audio('background', '/game-assets/sounds/background.mp3');
    this.load.audio('jump', '/game-assets/sounds/jump.mp3');
    this.load.audio('question', '/game-assets/sounds/get-question.mp3');
    this.load.audio('punch', '/game-assets/sounds/punch2.mp3');

    this.load.spritesheet(
      'dude',
      this.gameData.getState()?.gender === 'male'
        ? this.isAndroid()
          ? '/game-assets/man-1xx.png'
          : '/game-assets/man-2x.png'
        : this.isAndroid()
          ? '/game-assets/woman-1x.png'
          : '/game-assets/woman-2x.png',
      {
        frameWidth: this.isAndroid() ? 70 : 140,
        frameHeight: this.isAndroid() ? 170 : 340,
      },
    );

    this.load.spritesheet('cop', '/game-assets/cop-2x.png', {
      frameWidth: 142,
      frameHeight: 340,
    });
  }

  create() {
    this.obstacles = this.physics.add.group({
      immovable: false,
      allowGravity: false,
    });

    this.platform = this.physics.add.group({
      immovable: true,
      allowGravity: false,
    });

    this.questions = this.physics.add.group({
      immovable: true,
      allowGravity: false,
    });

    this.backgroundSound = this.sound.add('background', {
      loop: true,
      volume: 0.1,
    });

    this.punchSound = this.sound.add('punch', {
      loop: true,
      volume: 0.4,
    });

    this.jumpSound = this.sound.add('jump', {
      loop: false,
      volume: 0.1,
    });

    this.questionSound = this.sound.add('question', {
      loop: false,
      volume: 0.1,
    });

    this.backgroundSound.play();

    this.platform.create(100, 390, 'platform');

    if (this.isAndroid()) {
      this.background = this.physics.add
        .sprite(170, 400, 'office_bg')
        .setScale(2, 1.7)
        .setImmovable(true)
        .refreshBody();
    } else {
      this.background = this.physics.add
        .sprite(2600, 400, 'office_bg')
        .setScale(0.5, 0.6)
        .setImmovable(true)
        .refreshBody();
    }

    this.background.refreshBody();
    //@ts-ignore
    this.background.body.setAllowGravity(false);

    if (this.isAndroid()) {
      this.player = this.physics.add
        .sprite(170, 100, 'dude')
        .setDisplaySize(70, 170)
        .refreshBody();
    } else {
      this.player = this.physics.add
        .sprite(170, 100, 'dude')
        .setScale(0.5, 0.5)
        .setDisplaySize(70, 170)
        .refreshBody();
    }

    this.cop = this.physics.add
      .sprite(80, 100, 'cop')
      .setScale(0.5, 0.5)
      .setDisplaySize(70, 170)
      .refreshBody();

    this.player.setCollideWorldBounds(true);
    this.player.setGravityY(700);

    this.cop.setCollideWorldBounds(false);
    this.cop.setGravityY(700);

    this.anims.create({
      key: 'stop',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 26,
        end: 26,
      }),
      frameRate: 1,
      repeat: -1,
    });

    this.anims.create({
      key: 'right',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 0,
        end: 17,
      }),
      frameRate: 11,
      repeat: -1,
    });

    this.anims.create({
      key: 'right-cop',
      frames: this.anims.generateFrameNumbers('cop', {
        start: 0,
        end: 11,
      }),
      frameRate: 11,
      repeat: -1,
    });

    this.anims.create({
      key: 'punch-cop',
      frames: this.anims.generateFrameNumbers('cop', {
        start: 18,
        end: 24,
      }),
      frameRate: 20,
      repeat: -1,
    });

    this.anims.create({
      key: 'punch',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 26,
        end: 32,
      }),
      frameRate: 20,
      repeat: -1,
    });

    this.anims.create({
      key: 'up',
      frames: this.anims.generateFrameNumbers('dude', {
        start: 8,
        end: 11,
      }),
      frameRate: 6,
      repeat: 0,
    });

    for (let i = 0; i < 100; i++) {
      this.questions
        .create(
          600 * i ** 3 + 1600 + this.player.x,
          this.Y_ZERO_OBSTACLES,
          'q-violet',
        )
        .setScale(0.5);
      this.questions
        .create(300 * i ** 4 + 4000, this.Y_ZERO_OBSTACLES, 'q-green')
        .setScale(0.5);
    }

    this.obstacles
      .create(500, this.Y_ZERO_OBSTACLES - 8, 'bookshelf')
      .setScale(0.5);

    this.obstacles
      .create(700, this.Y_ZERO_OBSTACLES + 3, 'plague')
      .setScale(0.6);

    this.obstacles
      .create(900, this.Y_ZERO_OBSTACLES - 20, 'table')
      .setScale(0.5);

    for (let i = 0; i < 20; i++) {
      this.obstacles
        .create(500 * i + this.player.x * 10, this.Y_ZERO_OBSTACLES, 'printer')
        .setScale(0.5);
      this.obstacles
        .create(700 * i, this.Y_ZERO_OBSTACLES, 'plague')
        .setScale(0.6);

      this.obstacles
        .create(
          900 * i + this.player.x * 60,
          this.Y_ZERO_OBSTACLES - 20,
          'table',
        )
        .setScale(0.5);
    }

    this.physics.add.collider(this.platform, this.player);
    this.physics.add.collider(this.platform, this.cop);

    this.physics.add.collider(this.player, this.obstacles, () => {
      this.isStop = true;
    });

    this.colliderPlayerAndQuestions = this.physics.add.collider(
      this.player,
      this.questions,
      () => {
        setTestStore(true);
        this.questionSound.play();
        this.isStop = true;
      },
    );

    this.physics.add.collider(this.platform, this.obstacles);
    this.physics.add.collider(this.platform, this.questions);

    this.cursors =
      this.input.keyboard?.createCursorKeys() as Phaser.Types.Input.Keyboard.CursorKeys;

    this.physics.collide(this.player, this.platform);
    this.attempt = this.gameData.getState()!.attempts;
    EventBus.emit('current-scene-ready', this);
  }

  update(): void {
    if (this.isOpenModal.getState()) {
      this.game.pause();
    } else {
      this.game.resume();
    }

    if (this.gameData.getState()!.attempts < this.attempt) {
      console.log('ATTEMPT', {
        state: this.gameData.getState()?.attempts,
        attempt: this.attempt,
      });
      this.attempt = this.gameData.getState()!.attempts;
      this.isPunchOnBadAnswer = true;
    }

    if (!this.isPunchOnBadAnswer) {
      if (this.cop.x < this.player.x - 50) {
        this.cop.setVelocityX(-60);
      }

      if (this.player.x < 80 && this.player.y < 230) {
        this.player.setVelocityX(180);
        this.cop.setVelocityX(170);
      } else {
        this.player.setVelocityX(0);
        this.cop.setVelocityX(0);
      }

      if (this.player.y < 211) {
        this.isStop = false;
      }

      if (this.isStop) {
        if (this.cop.x < this.player.x - 45) {
          this.cop.setVelocityX(70);
          this.player.anims.play('stop');
        } else {
          this.cameras.main.flash(200, 255, 0, 0);

          this.gameOverEvent = this.time.addEvent({
            delay: 1300,
            callback: this.gameOver,
            callbackScope: this,
          });

          this.previousPlayerX = this.player.x;
          this.previousPlayerY = this.player.y;

          this.cop.setVelocityX(0);
          this.player.anims.play('punch', true);
          this.cop.anims.play('punch-cop', true);
        }
        this.punchSound.stop();
        this.background.setVelocityX(0);
        this.obstacles.setVelocityX(0);
        this.questions.setVelocityX(0);
      }

      if (this.background.x < -2200) {
        this.background.setX(2600);
      }

      if (!this.isStop) {
        if (this.cop.x <= this.player.x - 10) {
          this.cop.setVelocityX(-10);
        } else {
          this.cop.setVelocityX(0);
        }

        this.background.setVelocityX(-160);
        this.obstacles.setVelocityX(-160);
        this.questions.setVelocityX(-160);
        this.player.anims.play('right', true);
        this.cop.anims.play('right-cop', true);
      }

      // @ts-ignore
      if (
        (this.cursors.up.isDown ||
          this.input.mousePointer.isDown ||
          this.input.pointer1.isDown) &&
        this.player.body.touching.down
      ) {
        this.jumpSound.play();
        this.player.setVelocityY(-480);
        this.player.setVelocityX(+15);
        this.player.anims.pause();
        this.player.anims.play('up', true);
      }
    } else {
      this.punchOnBadAnswer();
    }
  }

  punchOnBadAnswer() {
    this.player.setX(190);
    this.cop.setX(190);
    this.colliderPlayerAndQuestions.active = false;

    if (this.cop.x < this.player.x - 45) {
      this.cop.setVelocityX(100);
      this.player.anims.play('stop');
    } else {
      this.cameras.main.flash(200, 255, 0, 0);

      this.badAnswerTimeEvent = this.time.addEvent({
        delay: 700,
        callback: this.badAnswerTimeEventCallBack,
        callbackScope: this,
      });

      this.cop.setVelocityX(0);
      this.player.anims.play('punch', true);
      this.cop.anims.play('punch-cop', true);
    }
    this.gameOverEvent?.remove();
    this.gameOverEvent = undefined;

    this.punchSound.stop();
    this.background.setVelocityX(0);
    this.obstacles.setVelocityX(0);
    this.questions.setVelocityX(0);
  }

  badAnswerTimeEventCallBack() {
    console.log('BAD ANSWER TIME EVENT');
    this.colliderPlayerAndQuestions.active = true;
    this.isPunchOnBadAnswer = false;
    this.isStop = false;
    this.cop.setX(140);
    this.player.setX(177);
  }

  startGame() {
    this.scene.start('Game');
  }

  gameOver() {
    if (this.isPunchOnBadAnswer) {
      this.gameOverEvent?.remove();
      this.gameOverEvent = undefined;
      return;
    }

    if (
      (this.player.x !== this.previousPlayerX ||
        this.player.y !== this.previousPlayerY) &&
      this.gameData.getState()?.attempts !== 0
    ) {
      this.gameOverEvent?.remove();
      this.gameOverEvent = undefined;
      setGameOver(false);
    } else {
      setGameOver(true);
      console.log('GAME OVER');
    }
  }
}
