import { createEvent, createStore } from 'effector';

export interface GameStore {
  gender: 'male' | 'female' | null;
  attempts: number;
}

export const gameStore = createStore<GameStore | null>(null);
export const testQuestionsStore = createStore<Boolean | null>(false, {
  skipVoid: false,
});

export const gameOverStore = createStore<Boolean | null>(false, {
  skipVoid: true,
});

export const setGameOver = createEvent<Boolean>();

export const setStore = createEvent<GameStore>();
export const setAttempt = createEvent<number>();
export const setTestStore = createEvent<Boolean>();

gameOverStore.on(setGameOver, (_store, payload) => {
  return payload;
});

testQuestionsStore.on(setTestStore, (_store, payload) => payload);

gameStore.on(setStore, (_store, payload) => {
  return payload;
});

gameStore.on(setAttempt, (store, payload) => {
  return { ...store!, attempts: payload };
});
