import {useNavigate, useParams} from "react-router-dom";
import {BreadCrumbItem, BreadCrumbs} from "@/ui/breadCrumbs";
import {useEffect, useState} from "react";
import {Star} from "../../../assets/icons/star.tsx";
import {clsx} from "clsx";
import {isNumber} from "lodash";
import reviewBoy from "../../../assets/svgs/review-boy.svg";
import helpBoy from "../../../assets/svgs/help-boy.svg";


export const ReviewPage = () => {
    const {dayId} = useParams();
    const [course, setCourse] = useState<string>();
    const navigate = useNavigate();
    const allScores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].sort((a, b) => b + a)
    const [clickedItem, setClickedItem] = useState<number | undefined>();
    const [isSentReview, setIsSentReview] = useState<boolean>(false);

    useEffect(() => {
        fetch(`/api/days/${dayId}/themes`)
            .then(response => response.json())
            .then(data => {
                setCourse(data.course);
            });
    }, [dayId]);


    const breadCrumbsItems: BreadCrumbItem[] = [
        {
            text: course!,
            isActive: false,
            onClick: () => navigate("/"),
        },
        {
            text: `День #${dayId}`,
            isActive: false,
            onClick: () => navigate(`/day/${dayId}`),
        },
        {
            text: 'Оставить отзыв',
            isActive: true,
            onClick: () => {
            }
        }
    ]

    const ReviewItem = ({score}: { score: number }) => {

        return (
            <li
                className={clsx(clickedItem === score ? "bg-green" : "bg-soft-gray-30", "flex flex-row rounded-[16px] cursor-pointer px-3 py-2 gap-x-4 items-center my-2")}
                onClick={() => setClickedItem(score === clickedItem ? undefined : score)}>
                <Star fill={clickedItem === score ? "#FFF" : "#00B956"}/>
                <span
                    className={clsx(clickedItem === score ? "text-white" : "text-green", "text-xs font-bold")}>{score}</span>
            </li>
        )
    }


    const handleSubmit = () => {
        if (isNumber(clickedItem) && !isSentReview) {
            fetch('/api/rate', {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    day: Number(dayId),
                    rating: Number(clickedItem)
                })
            }).then(() => {
                setIsSentReview(true);
            })
        } else {
            navigate('/')
        }
    }


    return (
        <div>
            <h1 className="mt-[18px] text-white text-3xl font-bold leading-9">Оставить отзыв</h1>
            <div className="text-white leading-6 font-medium opacity-70 text-base mt-2">{dayId}-й день ожидания</div>
            <BreadCrumbs items={breadCrumbsItems}/>
            <div className="flex w-full flex-col p-4 bg-white gap-y-1 shadow-1 rounded-xl mt-8">

                {!isSentReview ? (
                    <p className="text-xs font-medium leading-4">Оцени по 10-бальной шкале, насколько тебе была понятна
                        тема
                        дня,
                        где 10 - все понятно, 0 - вообще ничего не понятно.</p>) : (
                    <div>
                        <h2 className="text-[18px] font-medium text-black leading-6"> Ваша оценка отправлена</h2>
                        <img src={reviewBoy} className="max-w-[256px] max-h-[160px] mt-[16px] mb-[32px] mx-auto"/>
                        <p className="mb-[16px] text-xs font-medium">Спасибо, что делаете нас лучше!</p>
                    </div>

                )}

                {!isSentReview && (<ul>
                    {allScores.sort((a, b) => b - a).map(score => <ReviewItem score={score} key={score}/>)}
                </ul>)}


                <button disabled={!isNumber(clickedItem)} onClick={handleSubmit}
                        className="bg-purple disabled:bg-soft-gray-50 w-full text-white font-medium rounded-[22px] py-2.5">
                    {isSentReview ? "вернуться к курсу" : "отправить"}
                </button>
            </div>
        </div>
    )
}
