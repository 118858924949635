import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {BreadCrumbItem, BreadCrumbs} from "@/ui/breadCrumbs";
import "./content.css"
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules'
import 'swiper/css';
import './pagination_slider.css';
import {Progress} from "@/ui/progress/progress.tsx";
import {clsx} from "clsx";
import _, {throttle} from "lodash";
import {Loader} from "@/ui/loader";

const Content: React.FC<{
  slides: string[],
  oldView?: boolean,
  contentProgress: number,
  setContentProgress: React.Dispatch<React.SetStateAction<number>>
}> = ({slides, oldView, setContentProgress, contentProgress}) => {


  if (oldView) {
    const pagination = {
      clickable: true,
      dynamicBullets: true,
    };


    return (
      slides && (
        <Swiper modules={[Pagination]} pagination={pagination} spaceBetween={16} slidesPerView={"auto"}
                slidesOffsetBefore={16} slidesOffsetAfter={16}>
          {slides.map((slide, index) => {
            return (
              <SwiperSlide key={index}
                           className="!w-[calc(100%-32px)]">
                <div className="flex relative flex-col p-4 bg-white gap-y-1 rounded-xl mt-8"
                     dangerouslySetInnerHTML={{__html: slide}}/>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )
    )
  }


  const handleScroll = throttle((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {currentTarget} = e;
    if (currentTarget) {
      setContentProgress(prevProgress => {
        const newProgress = Number((currentTarget.scrollTop / (currentTarget.scrollHeight - currentTarget.clientHeight) * 100).toFixed(0));

        if (isNaN(newProgress)) return 0;


        if (newProgress !== prevProgress) {
          return newProgress;
        }
        return prevProgress;
      });
    }
  }, 60)


  return (
    slides && (
      <div
        className={clsx(contentProgress > 0 ? "top-4 h-[calc(100vh-12rem+110px)]" : "top-[126px] h-[calc(100vh-12rem)]", "flex flex-col absolute  self-center  p-4 bg-white gap-y-2 rounded-xl !w-[calc(100%-32px)] transition-all ")}>
        <Progress value={contentProgress}
                  className={clsx("bg-soft-pirple-20 mb-3 transition-all", contentProgress === 0 ? "opacity-0" : "opacity-100")}/>
        <div onScroll={handleScroll}
             className="overflow-y-scroll scrollbar-hide">
          {slides.map((slide, index) => (<div key={index} dangerouslySetInnerHTML={{__html: slide}}/>))}
        </div>
      </div>

    )
  )


}

const Section: React.FC = () => {

  const {dayId, themeId, sectionId} = useParams();
  const navigate = useNavigate();

  const [course, setCourse] = useState<string>();
  const [day, setDay] = useState<number>();
  const [theme, setTheme] = useState<string>();
  const [section, setSection] = useState<string>();
  const [slides, setSlides] = useState<string[]>();
  const [contentProgress, setContentProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("CONTENT PROGRESS", contentProgress)
  }, [contentProgress]);


  useEffect(() => {
    fetch(`/api/days/${dayId}/themes/${themeId}/sections/${sectionId}`)
      .then(response => response.json())
      .then(data => {
        setCourse(data.course);
        setDay(data.day);
        setTheme(data.theme);
        setSection(data.section);
        setSlides(data.slides);
      }).catch(() => {
      setDay(0);
      setCourse('');
      setSection('');
      setSlides([]);
    }).then(() => setIsLoading(false));
  }, []);


  const breadCrumbsItems: BreadCrumbItem[] = [
    {
      text: course!,
      onClick: () => navigate("/"),
      isActive: false,
    },
    {
      text: `День #${day}`,
      onClick: () => navigate(`/day/${dayId}`),
      isActive: false,
    },
    {
      text: theme!,
      onClick: () => navigate(`/day/${dayId}/themes/${themeId}`),
      isActive: false,
    },
    {
      text: section!,
      onClick: () => navigate(`/day/${dayId}/themes/${themeId}/sections/${sectionId}`),
      isActive: true,
    }
  ]


  return isLoading ? <Loader/> : (
    <div className="flex flex-col">
      {((day && theme && section) && <BreadCrumbs items={breadCrumbsItems}
                                                  className={clsx(contentProgress > 0 ? "opacity-0" : "opacity-100", "transition-all")}/>)}
      <Content slides={slides!} contentProgress={contentProgress} setContentProgress={setContentProgress}/>
    </div>
  );
}


export {Section};
