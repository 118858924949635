import React from "react";
import {clsx} from "clsx";
import lockIcon from '../../../../assets/svgs/lock.svg'
import {CheckCircle} from "../../../../assets/icons/check-circle.tsx";

interface DayButtonProps {
  day: number;
  completed: boolean;
  isLocked?: boolean;
  onClick?: () => void;
}


const DayButton: React.FC<DayButtonProps> = ({day, completed, onClick, isLocked}) => {


  if (isLocked) {
    return (
      <button disabled
        className="flex h-[58px] user-select-none flex-col items-center justify-center rounded-2xl bg-soft-pirple-20 cursor-not-allowed px-4 py-2">
          <img src={lockIcon} alt="lock" className="w-[18px] h-[18px]"/>
      </button>
    )
  }


  return (
    <button
      onClick={onClick}
      className={clsx(completed ? "bg-soft-green-20 text-green" : "bg-light-basic-content text-light-basic-content-2", "flex relative flex-col h-[58px] user-select-none items-center justify-center rounded-2xl px-4 py-2")}>
        {completed && (
            <CheckCircle isChecked={true} className="absolute stroke-green right-2 top-2 w-3.5 h-3.5"/>
        )}
        <span className="text-lg font-medium leading-6">{day}</span>
      <span className="text-light-basic-content-2 text-xs font-medium leading-[18px] lowercase opacity-40">День</span>
    </button>
  );
}

export {DayButton, type DayButtonProps};
