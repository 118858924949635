interface CheckCircleProps {
  className?: string;
  isChecked?: boolean;
}


export const CheckCircle: React.FC<CheckCircleProps> = ({className, isChecked}) => {
  if (!isChecked) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"
           className={className}>
        <circle opacity="0.2" clipPath="url(#clip0_870_7225)" cx="7" cy="7" r="6" stroke="#333333" strokeWidth="2"/>
        <defs>
          <clipPath id="clip0_870_7225">
            <rect width="14" height="14" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"
         className={className}>
      <g clipPath="url(#clip0_870_7220)">
        <path
          d="M12.8333 6.46334V7C12.8326 8.25792 12.4253 9.4819 11.6721 10.4894C10.9189 11.4969 9.86021 12.2339 8.65392 12.5906C7.44763 12.9473 6.15836 12.9044 4.9784 12.4685C3.79844 12.0326 2.79101 11.2269 2.10635 10.1716C1.4217 9.11636 1.0965 7.86804 1.17927 6.61285C1.26204 5.35767 1.74833 4.16286 2.56562 3.20663C3.38292 2.2504 4.48742 1.58398 5.7144 1.30675C6.94139 1.02953 8.22512 1.15637 9.37413 1.66834"
          stroke="currentStroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.8333 2.33325L7 8.17242L5.25 6.42242" stroke="currentStroke" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_870_7220">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
