import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/ui/table/table';
import logo from '../../assets/svgs/logo.svg';
import { useEffect, useState } from 'react';
import { clsx } from 'clsx';

interface RatingMember {
  id: number;
  name: string;
  rating: number;
}

export const RatingTable = () => {
  const [rating, setRating] = useState<RatingMember[]>([]);
  const [me, setMe] = useState<RatingMember | null>(null);

  useEffect(() => {
    fetch('/api/game/ratings')
      .then((response) => response.json())
      .then((data) => {
        console.log('RATINGG', data);
        setRating(data);
      });

    fetch('/api/game/member')
      .then((response) => response.json())
      .then((data) => {
        console.log('ME', data);
        setMe(data);
      });
  }, []);

  return (
    <div className="px-7 flex flex-col items-center">
      <img
        src={logo as unknown as string}
        className="h-[73px] w-[230px] mt-[50px]"
        alt="logo"
      />
      <div className="flex rounded-[12px] shadow-1 flex-col bg-white px-4 py-5 mt-[100px]">
        <span>Таблица «Рейтинг игроков»</span>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[300px]"></TableHead>
              <TableHead className="bg-soft-green-20 rounded mb-1">
                Пробежал
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="overflow-y-scroll scrollbar-hide">
            {rating.map((member) => (
              <TableRow>
                <TableCell
                  className={clsx(
                    me?.name === member.name ? '!bg-green text-white' : '',
                    'font-medium',
                  )}
                >
                  {me?.name === member.name ? 'Вы' : member.name}
                </TableCell>
                <TableCell>{member.rating}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
