import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {BreadCrumbs} from "@/ui/breadCrumbs";
import {clsx} from "clsx";
import {CheckCircle} from "../../../assets/icons/check-circle.tsx";
import {Loader} from "@/ui/loader";


interface Section {
    id: number;
    title: string;
    completed: boolean;
}


const Sections: React.FC<{ sections: Section[], dayId: string, themeId: string }> = ({sections, dayId, themeId}) => {
    const navigate = useNavigate();


    return (
        <ul className="flex w-full flex-col p-4 bg-white gap-y-1 shadow-1 rounded-xl mt-8">
            {sections.map((section, index) => (
                <li key={section.id} onClick={() => navigate(`/day/${dayId}/themes/${themeId}/sections/${section.id}`)}
                    className={clsx(section.completed ? "bg-soft-green-20" : "bg-soft-gray-20", "flex flex-row relative p-4 list-none justify-start rounded-[16px]")}>
                    <span className="font-medium text-xs leading-4">{index + 1}. {section.title}</span>
                    <CheckCircle className="stroke-purple h-3.5 absolute right-2.5 top-2.5"
                                 isChecked={section.completed}/>
                </li>
            ))}
        </ul>
    )
}


const Theme: React.FC = () => {
    const navigate = useNavigate();
    const {dayId, themeId} = useParams();
    const [course, setCourse] = useState<string>();
    const [day, setDay] = useState<number>();
    const [theme, setTheme] = useState<string>();
    const [sections, setSections] = useState<Section[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`/api/days/${dayId}/themes/${themeId}/sections`)
            .then(response => response.json())
            .then(data => {
                setCourse(data.course);
                setDay(data.day);
                setTheme(data.theme);
                setSections(data.sections);
            }).then(() => setIsLoading(false));
    }, [])


    const breadCrumbsItems = [
        {
            text: course,
            onClick: () => navigate("/"),
            isActive: false,
        },
        {
            text: `День #${day}`,
            onClick: () => navigate(`/day/${dayId}`),
            isActive: false,
        },
        {
            text: theme!,
            onClick: () => navigate(`/day/${dayId}/themes/${themeId}`),
            isActive: true,
        }
    ]


    return isLoading ? <Loader/> : (
        <div>
            <h1 className="mt-[18px] text-white text-3xl font-bold leading-9">{day}-й день</h1>
            <span
                className="text-white leading-6 font-medium opacity-70 text-base mt-1.5">Ознакомьтесь с темами дня</span>
            {(day && theme) && <BreadCrumbs items={breadCrumbsItems}/>}
            <Sections sections={sections} dayId={dayId!} themeId={themeId!}/>
        </div>
    )
}


export {Theme};
