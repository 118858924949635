import React from "react";
import {ArrowRight} from "../../../assets/icons/arrow-right.tsx";
import {clsx} from "clsx";
import {useMatch} from "react-router-dom";

interface BreadCrumbItem {
    text: string,
    isActive: boolean,
    onClick: () => void,
}

interface BreadCrumbsProps {
    items: BreadCrumbItem[];
    className?: string;
}


const BreadCrumbs: React.FC<BreadCrumbsProps> = ({items, className}) => {
    const isSection = useMatch('/day/:dayId/themes/:themeId/sections/:sectionId');

    return (
        <div
            className={clsx(isSection ? "px-4 absolute top-16" : "mt-6", "flex flex-row scrollbar-hide w-full gap-x-1.5  overflow-x-auto", className)}>
            {items.map((item, index) => {
                    return (
                        <button
                            key={index}
                            onClick={item.onClick}
                            className={clsx(item.isActive ? "text-green bg-white" : "text-white bg-white-10", isSection ? "pr-3" : "pr-2", "flex shrink-0 flex-row gap-x-1.5 rounded-xl py-[3px] items-center justify-center pl-3")}
                        >
                            <span className="text-xs font-medium leading-6">{item.text}</span>
                            {(isSection && (items.length -1 !== index)) &&
                                <ArrowRight className={clsx(item.isActive ? "fill-green" : "fill-white")}/>}

                            {!isSection && <ArrowRight className={clsx(item.isActive ? "fill-green" : "fill-white")}/>}
                        </button>
                    );
                }
            )}
        </div>
    );
};

export {BreadCrumbs, type BreadCrumbItem};
