interface ArrowRightProps {
  className?: string;
  size?: number;
}




export const ArrowRight: React.FC<ArrowRightProps> = ({className, size = 16}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" className={className}>
      <path d="M6.25 10.4L8.65 8L6.25 5.6L7.05 4.75L10.25 8L7.05 11.25L6.25 10.4Z"/>
    </svg>
  )
}
