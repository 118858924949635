import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";
import {v4 as uuidv4} from 'uuid';

import searchIcon from '../../../assets/svgs/search.svg';
import closeIcon from '../../../assets/svgs/close.svg';
import boyIcon from '../../../assets/svgs/searching-boy.svg';
import axios, {CancelTokenSource} from "axios";

function extractAndMergeParagraphs(htmlString: string) {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString.trim();

    // Находим все теги <p>
    let allPTags = tempDiv.querySelectorAll('p');

    if (allPTags.length > 0) {
        let combinedContent = Array.from(allPTags)
            .map((pTag) => pTag.innerHTML.trim()) // Получаем содержимое каждого тега <p> без пробелов по краям
            .join(' '); // Соединяем все содержимое в одну строку с пробелами

        // Удаляем неразрывные пробелы из объединенного содержимого
        combinedContent = combinedContent.replace(/&nbsp;|&#160;/gi, ' ');

        // Обрезаем содержимое до 30 символов
        combinedContent = combinedContent.substring(0, 40);

        // Создаем временный элемент div для хранения объединенного содержимого
        let tempCombined = document.createElement('div');
        tempCombined.innerHTML = `<p>${combinedContent}</p>`;

        return tempCombined.innerHTML;
    } else {
        // Если теги <p> не найдены, возвращаем исходную строку
        return htmlString;
    }
}
export const Search = () => {
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    const [sectionMatchText, setSectionMatchText] = useState<any[]>([]);
    const [searchResults, setSearchResults] = useState<{ themes: any[], sections: any[] }>({themes: [], sections: []});
    const [isLoading, setIsloading] = useState(false);
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | undefined>()

    useEffect(() => {

        if (searchValue.length === 0) {
            return;
        }


        setIsloading(true);

        if (typeof cancelToken != typeof undefined) {
            cancelToken!.cancel("cancel search.")
        }

        setCancelToken(axios.CancelToken.source());
    }, [searchValue]);


    useEffect(() => {
        if (!cancelToken) {
            return;
        }
        axios.get(`/api/search?text=${searchValue}`,
            {cancelToken: cancelToken?.token}
        ).then(req => req.data).then(data => setSearchResults(data)).finally(() => setIsloading(false));

    }, [cancelToken]);


    useEffect(() => {
        setSearchResults({themes: [], sections: []});
        if (searchResults) {
            setSearchResults(searchResults)
        }
        console.log(searchResults);
    }, [searchResults]);

    useEffect(() => {
        setSectionMatchText([]);
        searchResults.sections.map((item) => {
            setSectionMatchText((prev) => [...prev, {
                item: {
                    ...item,
                    text: item.slides,
                    slides: undefined
                }
            }]);
        })

    }, [searchResults]);


    useEffect(() => {

        if (searchValue.length === 0) {
            setSearchResults({themes: [], sections: []});
            setSectionMatchText([]);
        }
    }, [searchValue])


    useEffect(() => {
        console.log(searchResults.themes.length);
        console.log(sectionMatchText.length);
        console.log("searchResults", searchResults);
        console.log("sectionMatchText", sectionMatchText);
    }, [searchResults, sectionMatchText]);

    return (
        <div className="">
            <h1 className="mt-[18px] text-white text-3xl font-bold leading-9">Поиск</h1>
            <div
                className="flex flex-row mt-6 py-2.5 gap-x-2.5 h-11 bg-white w-full rounded-[22px] justify-start items-center">
                {searchValue.length > 0 ? (
                    <button className="ml-2.5" onClick={() => setSearchValue("")}><img src={closeIcon}
                                                                                       alt="close search"/>
                    </button>
                ) : (
                    <img src={searchIcon} className="ml-2.5" alt="search"/>
                )}
                <div className="h-full w-px bg-light-gray-2"/>
                <input
                    className="w-[calc(100%-4rem)] outline-none text-sm font-medium text-light-basic-content-2"
                    type="text"
                    placeholder="Поиск..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>

            {searchValue.length === 0 ?
                <div className="mt-[60px] w-full flex flex-col justify-center items-center">
                    {searchValue.length === 0 ? <img src={boyIcon} alt="searching boy"/> : null}
                </div> : null}


            {isLoading ? (
                <div className="text-center mt-[60px]">
                    <div role="status">
                        <svg aria-hidden="true"
                             className="inline w-16 h-16 mr-2 text-soft-green-20 animate-spin fill-green"
                             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"/>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"/>
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

            ) : null}

            {!isLoading && searchValue.length > 0 && (
                <div
                    className="mt-4 text-white font-medium text-[12px] leading-[14px]">{searchResults.themes.length + sectionMatchText.length === 0 ? "Ничего не найдено" : `результатов: ${searchResults.themes.length + sectionMatchText.length}`}</div>

            )}

            {!isLoading && searchResults.themes.length + sectionMatchText.length > 0 && (
                <div
                    className={clsx("flex flex-col max-h-[calc(100vh-17rem)] overflow-y-scroll overflow-x-hidden scrollbar-hide items-start p-4 mt-4 pt-[24px] bg-white gap-y-2 rounded-xl w-full transition-all")}>

                    {searchResults.themes.length > 0 && searchResults.themes.map((item: any) => (
                        <div key={uuidv4()} className="w-full border-b last:border-none border-light-gray-2 pb-3">
                            <div className="flex flex-col items-start">
                                <button onClick={() => navigate(`/day/${item.day.id}/themes/${item.id}`)}
                                        className="text-[15px] font-medium overflow-hidden text-light-basic-content-2 leading-[18px]">
                                    <p
                                        className="truncate max-w-[300px]">...{item.title}</p></button>

                                <div className="flex flex-row gap-x-1 mt-3">
                                    <button onClick={() => navigate(`/day/${item.day.id}`)}
                                            className="text-white flex-shrink-0 bg-purple text-xs leading-[14px] py-[4px] px-[6px] rounded-[9px]">День
                                        #{item?.day?.number}</button>
                                </div>
                            </div>
                        </div>
                    ))}


                    {!isLoading && sectionMatchText.map((item) => (
                        <div key={uuidv4()} className="w-full border-b last:border-none border-light-gray-2 pb-3">
                            <div className="flex flex-col items-start">
                                <button
                                    className="text-[15px] font-medium overflow-hidden text-light-basic-content-2 leading-[18px]"
                                    onClick={() => navigate(`/day/${item.item.theme.day.id}/themes/${item.item.theme.id}/sections/${item.item.id}`)}>
                                    <p className="truncate max-w-[300px]"  dangerouslySetInnerHTML={{ __html: extractAndMergeParagraphs(item.item.text) }}></p>
                                </button>
                                <div className="flex flex-row gap-x-1 mt-3">
                                    <button onClick={() => navigate(`/day/${item.item.theme.day.id}`)}
                                            className="text-white flex-shrink-0 bg-purple text-xs leading-[14px] py-[4px] px-[6px] rounded-[9px]">День
                                        #{item.item.theme.day.number}</button>
                                    <button
                                        onClick={() => navigate(`/day/${item.item.theme.day.id}/themes/${item.item.theme.id}`)}
                                        className="text-white text-ellipsis flex-shrink-0 bg-purple text-xs leading-[14px] py-[4px] px-[6px] rounded-[9px] max-w-[110px] truncate">{item.item.theme.title}</button>
                                    <button
                                        onClick={() => navigate(`/day/${item.item.theme.day.id}/themes/${item.item.theme.id}/sections/${item.item.id}`)}
                                        className="text-white text-ellipsis flex-shrink-0 bg-purple text-xs leading-[14px] py-[4px] px-[6px] rounded-[9px] max-w-[110px] truncate">{item.item.title}</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

