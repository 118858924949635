import React, { useEffect, useState } from 'react';
import { Day } from '../day/day';
import { DayButton } from './day/day';
import { useNavigate } from 'react-router-dom';
import helpIcon from '../../../assets/svgs/help.svg';
import { ArrowRight } from '../../../assets/icons/arrow-right.tsx';
import { Loader } from '@/ui/loader';

interface Day {
  id: number;
  number: number;
  completed: boolean;
  rated: boolean;
  locked: boolean;
}

// api/auth

const Main: React.FC = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState<string>();
  const [days, setDays] = useState<Day[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`/api/days`)
      .then((response) => response.json())
      .then((data) => {
        console.log('main page, req /api/days', JSON.stringify(data));
        setCourse(data.course);
        setDays(data.days);
      })
      .catch(() => {
        setCourse('');
        setDays([]);
        setIsLoading(true);
      })
      .then(() => setIsLoading(false));
  }, []);

  const HelpButton = () => {
    const navigate = useNavigate();
    return (
      <button
        onClick={() => navigate(`/help`)}
        className="mt-4 w-full py-3 px-4 bg-white rounded-[12px] flex flex-row items-center justify-between"
      >
        <div className="flex flex-row items-center gap-x-2.5">
          <img src={helpIcon} alt="иконка отзыва" />
          <span className="text-xs font-medium leading-4">
            Обращение в тех. поддержку
          </span>
        </div>
        <ArrowRight />
      </button>
    );
  };

  useEffect(() => {
    console.log(days);
  }, [days]);

  return (
    <>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex w-full h-full flex-col bg-white px-4 pb-4 pt-4 shadow-1 rounded-xl mt-6">
            <span className="uppercase text-3xl text-purple font-bold">
              {course}
            </span>
            <span className="text-light-basic-content-2 opacity-30 mt-1.5">
              Выберите день из списка
            </span>

            <div className="grid grid-cols-4 gap-2 mt-8">
              {days &&
                days.length > 0 &&
                days.map((day) => (
                  <DayButton
                    key={day.id}
                    day={day.number}
                    completed={day.completed}
                    isLocked={day.locked}
                    onClick={() => navigate(`/day/${day.id}`)}
                  />
                ))}
            </div>
          </div>
          <HelpButton />
        </>
      )}
    </>
  );
};

export { Main };
