import {
  gameStore,
  setAttempt,
  setGameOver,
  setTestStore,
} from '@/game/game-store.tsx';
import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import logo from '../../../assets/svgs/logo.svg';
import { useUnit } from 'effector-react';

type answer = {
  title: string;
  id: number;
  is_correct: boolean;
};

type answerWithIdx = answer & { idx: number };

interface Question {
  answers: answer[];
  title: string;
  id: number;
}

export const QuestionModal: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean>();

  const [ansver, setAnswer] = useState<answerWithIdx | null>(null);

  const [passedQuestions, setPassedQuestions] = useState<number[]>([]);

  useEffect(() => {
    fetch('api/game/questions')
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        console.log('questions', data);
        setIsLoading(false);
      });
  }, [isOpen]);

  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const $gameStore = useUnit(gameStore);

  const getRandomQuestion = (): Question => {
    const remainingQuestions = questions.filter(
      (question) => !passedQuestions.includes(question.id),
    );

    if (remainingQuestions.length === 0) {
      return questions[questions.length - 1];
    }

    const randomIndex = Math.floor(Math.random() * remainingQuestions.length);
    return remainingQuestions[randomIndex];
  };

  useEffect(() => {
    if (isOpen) {
      setActiveQuestion(getRandomQuestion());
    }
  }, [questions]);

  useEffect(() => {
    if (isOpen) {
      let timer = setTimeout(() => {
        setIsSubmitted(false);
        setTestStore(false);
        setActiveQuestion(getRandomQuestion());
      }, 5000);

      return () => {
        setActiveQuestion(getRandomQuestion());
        clearTimeout(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && isOpen) {
      fetch('/api/game/questions', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          question: activeQuestion?.id,
          answer: ansver?.idx,
        }),
      });

      let timer = setTimeout(() => {
        setTestStore(false);
        setActiveQuestion(getRandomQuestion());
        setIsSubmitted(false);
      }, 1000);
      return () => {
        setActiveQuestion(getRandomQuestion());
        clearTimeout(timer);
      };
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (!isCorrectAnswer && activeQuestion && isSubmitted) {
      const timer = setTimeout(() => {
        if ($gameStore!.attempts === 0) {
          setGameOver(true);
        } else {
          setAttempt($gameStore!.attempts - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const handleAnswer = (answer: answer, index: number) => {
    setIsCorrectAnswer(answer.is_correct);
    setPassedQuestions([...passedQuestions, activeQuestion!.id]);
    setAnswer({ ...answer, idx: index });
  };

  return (
    isOpen &&
    !isLoading && (
      <div className="left-0 z-[999] top-0 duration-300 transition-all flex flex-col absolute w-screen h-screen bg-gradient pt-6 px-4">
        <div className="flex flex-row justify-center items-center">
          <img src={logo} alt="logo" className="w-[230px] h-[73px]" />
        </div>
        <div className="bg-white flex flex-col rounded-[12px] p-4 mt-[64px]">
          <div className="text-[16px] font-semibold text-black">
            {activeQuestion?.title}
          </div>
          <div className="mt-4 flex flex-col gap-2">
            {activeQuestion?.answers.map((item, index) => (
              <div
                onClick={() => handleAnswer(item, index)}
                className={clsx(
                  'transition-all duration-300',
                  !isSubmitted &&
                    (ansver?.title === item.title
                      ? 'bg-soft-green-20 text-green'
                      : 'bg-soft-gray-30 text-green'),
                  isSubmitted &&
                    (item.is_correct
                      ? 'bg-green text-white'
                      : 'bg-red text-white'),
                  'rounded-[16px] px-3 py-2.5 text-[16px] font-medium cursor-pointer',
                )}
                key={item.title + item.is_correct}
              >
                {item.title}
              </div>
            ))}
          </div>
          <button
            className="mt-4 bg-purple rounded-[22px] text-white py-4 px-2 w-full"
            onClick={() => setIsSubmitted(true)}
          >
            Отправить
          </button>
        </div>
      </div>
    )
  );
};
