import redo from '../../../assets/svgs/redo.svg';
import redoWhite from '../../../assets/svgs/redo-white.svg';
import search from '../../../assets/svgs/search.svg';
import searchWhite from '../../../assets/svgs/search-white.svg';
import { useMatch, useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import React, { useEffect } from 'react';

interface MenuProps {
  isSecond?: boolean;
  isMain?: boolean;
  isGame?: boolean;
}

export const Menu: React.FC<MenuProps> = ({ isSecond, isMain, isGame }) => {
  const navigate = useNavigate();
  const isSection = useMatch('/day/:dayId/themes/:themeId/sections/:sectionId');
  const [course, setCourse] = React.useState<string>();

  useEffect(() => {
    fetch(`/api/days`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.course);
        setCourse(data.course);
      })
      .catch(() => {
        setCourse('');
      });
  }, []);

  // отклоненная реализация пагинации слайдов, попросили сохранить

  if (isSection && isSecond) {
    return (
      <div
        className={clsx(
          'w-full px-4 items-center h-[56px] absolute flex justify-between flex-row bottom-0 left-0',
        )}
      >
        <div className="w-full flex flex-row justify-between h-[40px] items-center bg-white shadow-2 p-[2px] rounded-[18px]">
          <button
            onClick={() => navigate(-1)}
            className="h-[36px] px-4 py-1.5 flex flex-row items-center bg-green justify-center z-50 rounded-[18px]"
          >
            <img src={redoWhite} alt="back button" className="h-6 w-6" />
          </button>

          <button className="bg-green h-[36px] px-4 py-1.5 flex flex-row justify-center z-50 rounded-[18px]">
            <img src={searchWhite} alt="search button" className="h-6 w-6" />
          </button>
        </div>
      </div>
    );
  }

  console.log(course?.toLowerCase(), isGame);

  const isShowGame =
    course?.toLowerCase().replace(/ /g, '') === 'фтм'.toLowerCase();

  return (
    <div
      className={clsx(
        isSection || isShowGame ? 'px-4 justify-between' : 'justify-between',
        'w-full h-[56px] sticky top-[100vh] flex place-items-end flex-row bottom-0 left-0',
      )}
    >
      {!isMain ? (
        <button
          disabled={isMain}
          onClick={() => (isShowGame ? navigate('/') : navigate(-1))}
          className={
            'bg-white h-[36px] px-4 py-1.5 flex flex-row items-center justify-center rounded-[18px] mb-[10px]'
          }
        >
          <img src={redo} alt="back button" className="h-6 w-6" />
        </button>
      ) : null}

      {isMain && isShowGame && (
        <button
          onClick={() => navigate('/game')}
          className="bg-white h-[36px] text-[#00B956] px-4 py-1.5 flex flex-row justify-center rounded-[18px] mb-[10px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-6 w-6"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M2 6m0 2a2 2 0 0 1 2 -2h16a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-16a2 2 0 0 1 -2 -2z" />
            <path d="M6 12h4m-2 -2v4" />
            <path d="M15 11l0 .01" />
            <path d="M18 13l0 .01" />
          </svg>
        </button>
      )}

      <button
        onClick={() => navigate('/search')}
        className="bg-white h-[36px] px-4 py-1.5 flex flex-row justify-center rounded-[18px] mb-[10px]"
      >
        <img src={search} alt="search button" className="h-6 w-6" />
      </button>
    </div>
  );
};
